import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Col, Row } from "reactstrap"

const Terms = () => (
  <Layout>
   {/* <Seo title='Terminos y condiciones'/> */}
    <Row className="px-5 mt-5">
        <Col xs='12' className="px-5 mt-5">
            <p className="fs-2 c-wedgewood text-center fw-bold"> TÉRMINOS Y CONDICIONES </p>
            <p className="fs-6 c-wedgewood"> 
                I.- CONDICIONES GENERALES <br/>
                </p>
        <p className="fs-6 c-wedgewood"> 
                El presente documento establece los términos y condiciones generales (los "Términos y Condiciones") que serán aplicables a los Usuarios (tal y como se define posteriormente) )de la plataforma digital e informática cuya titularidad corresponde a Kenko Tech, S.A. de C.V. (la "Plataforma" o "Kenko") así como al acceso y uso del sitio de internet localizado en la dirección electrónica URL https://www.kenko.mx/(el "Sitio Web") y cualesquier portal, desarrollo y/o programas adicionales o auxiliares que, directa o indirectamente, utilice Kenko para prestar sus servicios referidos todos ellos conjuntamente con la Plataforma, Sitio Web y  Medios Digitales.
                En el presente instrumento, Kenko podrá ser referido, indistintamente, como "Kenko" o "nosotros"; asimismo, las frases "nuestro" o "de nosotros" se entenderán referidas a Kenko. Los Términos y Condiciones también podrán ser referidos, indistintamente, como el "presente documento" o el "presente instrumento".
                Serán referidos como "Usuarios" todas las personas que se registren o accedan o utilicen la Plataforma, por cualquier dispositivo u ordenador. Donde fuera de aplicación, “Usuario” también incluirá a los Profesionales de Salud cuyos datos aparezcan en la Plataforma o que tengan un Perfil registrado. (tal y como este término se define posteriormente).
                Los presentes Términos y Condiciones se encuentran dirigidos a los Usuarios de la Plataforma, y les resultarán aplicables y vinculantes en todo momento que tengan la calidad de Usuarios y se encuentran dirigidos exclusivamente a los usuarios que se encuentren en el territorio de los Estados Unidos Mexicanos ("México").
            </p>
        <p className="fs-6 c-wedgewood"> 
            II.- ACEPTACIÓN </p>
        <p className="fs-6 c-wedgewood"> 
            Los presentes Términos y Condiciones serán desplegados frente a los Usuarios para su aceptación expresa, la cual podrá realizarse por escrito y a través de firma autógrafa o bien por medios electrónicos mediante el llenado y/o el marcado de casillas de aceptación habilitadas en los Medios Digitales, lo anterior con fundamento en la segunda fracción del Artículo 1803 del Código Civil Federal y sus correlativos y aplicables de los Códigos Civiles de las Entidades Federativas de la República Mexicana.
            Los Usuarios declaran y garantizan que, previamente al uso directo o indirecto de cualquiera de los Medios Digitales, han aceptado expresamente todas y cada una de las disposiciones contenidas y previstas en los presentes Términos y Condiciones, así como la sujeción correspondiente a los mismos, en términos del párrafo que antecede. En caso de que Kenko no tenga constancia de la previa aceptación de algún Usuario de lo previsto en el presente instrumento, no se habilitará el Servicio.
            Para el supuesto de que los Usuarios por cualquier motivo no deseen obligarse en los exactos Términos y Condiciones aquí establecidos, o de no estar conformes con la totalidad o una parte de las previsiones aquí contenidas, deberán abstenerse de realizar cualquier registro y de utilizar los Medios Digitales y en su caso, de utilizar los Servicios, además de abandonar inmediatamente cualquier Medio Digital que se encuentren utilizando o que hayan utilizado o donde se hayan registrado. Kenko garantizará el derecho de los Usuarios de revocar su consentimiento respecto de la aceptación del presente instrumento, en cuyo caso bastará con que el propio Usuario lo comunique al Centro de Atención Kenko.
            Los Pacientes que accedan a los Medios Digitales sin haber creado una Cuenta de Usuario (tal y como este término se define posteriormente) únicamente podrán navegar en el Sitio Web y enviar un mensaje directamente a los Especialistas en Salud, siendo en este último caso indispensable la aceptación expresa de los presentes Términos y Condiciones. Sin embargo, cualquier uso de los Medios Digitales tendrá como efecto que se repute tácitamente aceptado el presente instrumento por el Usuario, a pesar de que no se haya creado su respectiva cuenta.
        </p>
        <p className="fs-6 c-wedgewood"> 
            III.- DEFINICIONES<br/>
            Para mejor claridad y a efectos de estos Términos y Condiciones, los términos que aparezcan definidos en mayúsculas, tendrán el significado que se les atribuye a continuación o en cualquier lugar de estos términos y Condiciones:

            Paciente: Corresponde al tipo de Usuario que accede a la Plataforma con el propósito de obtener su expediente médico, registrar su información referente a salud, agendar citas sus profesionales de la salud o consultar sus recetas electrónicas.
            Centro Médico: Se refiere al tipo de Usuario que es  una clínica, centro de salud u hospital.
            Consultorio Médico: Se refiere al espacio físico dentro del cual un profesional de la salud brinda su servicio a sus pacientes.
            Profesional en Salud: Se refiere al profesionista médico, médico general, médico especialista, médico residente, enfermera, nutriólogo, homeopata, psicólogo, partera, pasante de cualquiera de las anteriores y personal administrativo y de apoyo para los servicios brindados del profesional de la salud.
            Suscripción: Corresponde al plan de suscripción elegido por el usuario para el uso de Kenko, cada plan de suscripción cuenta con diferentes caracteristicas y el usuario deberá seleccionar el que se adecue de mejor forma a práctica clínica.
        </p>
        <p className="fs-6 c-wedgewood"> 
            IV.- USUARIOS<br/>
            Kenko cuenta con cuatro categorías de Usuarios, (1) Pacientes; (2) Profesional de Salud; (3) Asístente y (4) Centro Médico <br/>
            Los Usuarios deberán registrarse en Kenko, accediendo a la misma por medio del Sitio Web, con el propósito de obtener una cuenta y una contraseña de acceso (la "Cuenta de Usuario"). El registro deberá realizarse por medio del formulario que esté dirigido a la categoría particular de Usuario que se trate.
            <br/>
            Los Usuarios deberán proporcionar la información personal correspondiente en todos los campos, con datos válidos, precisos y veraces. Los Usuarios están obligados a mantener la antedicha información personal actualizada en todo momento. Kenko se reserva el derecho de solicitar, en cualquier momento, determinada información o documentación a los Usuarios con el propósito de verificar la veracidad de la información proporcionada, así como de suspender temporalmente las Cuentas de Usuario cuya información no haya podido ser corroborada, o aquéllas cuentas que se estimen han sido creadas con propósitos fraudulentos.
            <br/>
            El registro en Kenko se realizará por medio del uso de el Sitio Web.
            <br/>
            Todos los Usuarios declaran que cuentan con capacidad para contratar, de acuerdo con la legislación aplicable en sus respectivos domicilios. Únicamente podrán solicitar la habilitación de una Cuenta de Usuario las personas que tengan mayoría de edad, es decir, dieciocho (18) años cumplidos al momento de registrarse en la Plataforma.
            <br/>
            Los Usuarios serán en todo momento responsables de la custodia de su contraseña, asumiendo en consecuencia, cualesquier daños y perjuicios que pudieran derivarse de su uso indebido, así como de la cesión, revelación o extravío de la misma. Asimismo, queda estrictamente prohibido para los Usuarios compartir su contraseña o permitir el acceso a su Cuenta de Usuario a cualquier tercero. En caso de olvido de la contraseña o de cualquier otra circunstancia que suponga un riesgo de acceso y/o utilización por parte de terceros no autorizados, el Usuario lo comunicará inmediatamente al Centro de Atención Kenko, a fin de que ésta proceda inmediatamente al bloqueo y sustitución de la misma. El Usuario reconoce el carácter personal e intransferible de la contraseña, la cual quedará bajo su custodia o cuidado, por lo que será exclusiva responsabilidad del Usuario cualquier daño o perjuicio que pudiese ser consecuencia del uso indebido de la misma.
        </p>
        <p className="fs-6 c-wedgewood"> 
            V.- SERVICIO Y CONTENIDOS

            La plataforma de salud digital Kenko tiene como proposito proveer herramientas digitales a profesionales de la salud y centros médicos, tales como agenda médica digital, expediente clínico electrónico y receta médica electrónica de manera que el profesional de la salud interactue de forma digital con sus pacientes antes y despues de la consulta. Lo anterior se realiza por medio del registro de información propia del profesional de la salud como del paciente, misma que es confidencial y pertence tanto al paciente como al usuario que la registra con fines profesionales.
            El Servicio que provee Kenko se limita a facilitar la labor, administracion e interaccipin entre Usuarios, por lo tanto, no implica que Kenko recomiende a Especialista en Salud o a Centro Médico Alguno, ni que Kenko verifique la idoneidad o la capacidad técnica de los Especialistas en Salud o que haga investigación documental alguna para certificar los conocimientos de los Profesionales en Salud registrados.


            Los Contenidos que publiquen o compartan los Usuarios podrán ser visibles para miembros y visitantes del Sitio Web de Kenko.

            Se requerirá el consentimiento del Usuario en caso de otorgar a otras personas el derecho a publicar sus Contenidos. No obstante, si el Usuario decide compartir su información, permitiremos que los motores de búsqueda muestren dicho Contenido en sus servidores.

            Los Usuarios otorgan a Kenko una licencia no exclusiva, ilimitada en el tiempo, libre de regalías para usar los Contenidos con los propósitos relativos a la prestación del Servicio.

            El Servicio de Kenko es gratuito para los Pacientes. Los profesionales de la salud tendrán que elegir un plan de suscripción SaaS por miembro registrado en la cuenta, dentro de los cuales existe la opción "Free" la cual es gratuita y solo con acceso de lectura a la información básica del paciente que lo comparta a su Profesional de la Salud, adicionalmente al plan de suscripción gratuito existen los planes de suscripción de pago "Starter", "Professional", "Professional Plus" y "Enterprise" los cuales permiten el registro de un miembro titular y multiples miembros adicionales registrados por el titular y responsable de la cuenta.
        </p>
        <p className="fs-6 c-wedgewood"> 
            VI.- ALCANCE DEL SERVICIO
            Por lo que hace a los Usuarios en general, el servicio se circunscribe a lo siguiente:<br/>
            Permitir a los usuarios profesional de la salud, el uso de herramientas digitales que brinden ayuda para la administración y ejecución de su práctica clínica al mismo tiempo que brinden herramientas digitales de interacción con sus pacientes.

            Permitir a los usuarios Pacientes, contar con un acceso fácil y siempre disponible a su propio expediente médico y el de sus familiares, el cual se construira de forma acumulativa con los profesionales de la salud que les brinden sus servicios y utilicen Kenko para su práctica clínica.

            Los usuarios Profesionales de la Salud suscritos en planes de pago contarán con los siguientes servicios:

            Plan de suscripción "Starter": directorio medico, perfil profesional, administración de consultorio, expediente clínico, gestión de pagos, CIE-11, directorio medico, link de reervacion de citas, manejo de agenda online, perfil profesional, prescripcion informada-PLM, receta digital, recordatorio a pacientes, repositorio de archivos, solicitud de laboratorios e imagenes.

            Plan de suscripción "Professional": contará además de todas las caracteristicas descritas en el plan Starter administración de múltiples consultorios, notificaciones via whatsapp, administración global de agenda.

            Plan de suscripción "Professional": contará además de todas las caracteristicas descritas en el plan Professional con administración global de expedientes por clinica, administración global de pacientes por clinica, gestión de miembros de la clinica.

            Dentro de las funcionalidades que Kenko brinda a sus usuarios se describen a continuación las principales:

            Agenda Médica Digital: Permite al profesional de la salud:
            (a) Registrar uno o más consultorios con base en su tipo de suscripción.
            (b) Registrar la disponibilidad en cada consultorio para modalidad presencial o teleconsulta. (c) Asignar usuarios asistentes a consultorios, (d) Capturar citas en base a la disponibilidad  y el tiempo promedio de consulta registrado por el usuario. (d) Contar con un link de agendamiento express, listo para ser compartido por redes sociales, mensajeria o cualquier medio digital que permita al paciente abrirlo y agendar una cita en base a la disponibilidad del profesional de la salud. (e) Mantener conectada la práctica clíni en cuanto a expediente y receta a la agenda clínica.


            Expediente Clínico electronico: Permite al profesional de la salud registrar la información clínica del paciente obtenida en consulta al realizar la observación, signos vitales,  diagnóstico y prescripción para un seguimiento y consulta historico


            Receta médica electrónica: Permite al profesional de la salud realizar la prescripción de medicamnetos a travez de la emisión de un documento digital único, irreplicable y seguro. 
        </p>
        <p className="fs-6 c-wedgewood"> 
VII.- DURACIÓN Y TERMINACIÓN
Los Usuarios que contraten cualquier plan de suscripción con costo podrán elegir la duración del compromiso al cuál se sujetan, es decir podrán elegir entre un compromiso "mensual" ó "anual". 

En el caso de que el usuario elija un compromiso mensual, éste podrá cancelarlo en cualquier momento y el cobro recurrente del proximos periodo será cancelado de forma automática.

En el caso de que el usuario elija un compromiso anual, éste no podrá ser cancelado hasta completarse el pago de 12 meses del servicio. El usuario podra cancelar la renovación del plan anual en cualquier momento para cancelar el cargo posterior a los 12 meses de servicio contratados. En el caso de que, antes de la terminación del compromiso anual, el Usuario decida dar de baja y/o dejar de utilizar el Servicio, o de que el contrato o estos Términos y Condiciones sean rescindidos por Kenko debido a la falta de pago de las contraprestaciones por parte del Usuario, el Usuario deberá pagar a Kenko, como indemnización, el resto de las cuotas de contraprestaciones restantes hasta la finalización del Período Mínimo (la "Indemnización"). Esta Indemnización podrá ser requerida por parte de Kenko en un solo pago y dentro de los treinta (30) días posteriores a la fecha en que la Indemnización se haya hecho exigible al Usuario.
</p>
<p className="fs-6 c-wedgewood"> 

VIII.- CONTRAPRESTACIÓN
Kenko tendrá derecho a percibir la retribución acordada al momento de la suscripción, al inicio de cada periodo de facturación en función del tipo de suscripción, del número de Perfiles asignados a la cuenta, y del momento en el que se produjo dicha asignación.

En los casos en que la suscripción inicial se haya realizado bajo alguna promoción o precio especial en su momento vigente, la contraprestación en favor de Kenko será la del precio regular vigente al momento en que la suscripción sea renovada de manera automática de conformidad con el número VII anterior. Asimismo, las suscripciones con precio especial, serán cargadas al Usuario con el precio regular vigente, una vez se termine el plazo de la promoción e independientemente de si se trata de renovación automática o no.

Salvo que se indique lo contrario, la retribución especificada es neta de impuestos y gastos.

Kenko podrá modificar la contraprestación acordada con el Usuario, en cuyo caso, Kenko notificará al Usuario la fecha en la que esta modificación surtirá efectos.


Para contar con una suscripción a un plan de pago dentro de Kenko el usuario debera proporcionar la información de una trajeta de credito/debito a la cual se estarán realizando los cargos recurrentes en base al monto y tipo de compromiso del plan elegido. Por seguridad Kenko no almacena la información de tarjetas bancarias, Kenko realiza el procesamiento suscripciones, pagos y registro de medios de pago a través de la Plataforma de Pagos Openpay by BBVA.
</p>
<p className="fs-6 c-wedgewood"> 
IX.- TÉRMINOS DEL SERVICIO
Para poder acceder al Sitio Web cuya titularidad corresponde a Kenko, se deben cumplir las siguientes condiciones técnicas:

Tener acceso a internet.
Contar con un navegador de Internet instalado, los cuales pueden ser alguno de los siguientes: Firefox, Chrome, Safari, IE, Opera, actualizado a la última versión.
Los Pacientes pueden reservar una cita con los Profesionales de la Salud y en su caso, sujeto a la verificación del número de teléfono celular y la dirección de correo electrónico. En algunos casos, los Profesionales de la Salud y en su caso, los Centros Médicos pueden requerir la verificación de la identidad del Paciente con documento idóneo.

En caso de resultar aplicable, el Servicio solamente se podrá concretar si el Paciente ha aceptado previamente el Aviso de Privacidad Kenko, y en específico, si ha otorgado el consentimiento expreso respecto del tratamiento de sus datos personales sensibles relativos a su estado de salud.

Kenko no será responsable ante los Profesionales de la Salud y en su caso, con los Centros Médicos por el hecho de que el Paciente no se presente a la hora acordada, reservada a través del Calendario de Visitas. Kenko tampoco será responsable ante el Paciente por la imposibilidad de cumplir con la cita reservada por el calendario de visitas por motivos imputables a los Profesionales de la Salud o en su caso, a los Centros Médicos. Si es necesario cancelar la visita, las partes interesadas deben cancelar la reserva a través de los Medios Digitales.
</p>
<p className="fs-6 c-wedgewood"> 

X.- USO Y VERIFICACIÓN DE PERFILES DE PROFESIONALES DE LA SALUD
El uso y la verificación de las Cuentas de Usuario de los Profesionales de la Salud, así como de los Centros Médicos, quedará sujeto a los siguientes lineamientos:

A. Lineamientos aplicables al perfil del Usuario  Profesional de la Salud.

Para crear este perfil se requiere al menos información básica sobre el Profesional de la Salud.
La información básica sobre el Profesional de la Salud incluye: (i) cédula profesional; (ii) nombre y apellidos; (iii) curp; (iv) INE; 
Un Profesional de la Salud sólo puede tener un perfil, por lo que por ninguna razón está permitido crear un perfil común para varios Profesionales de la Salud. Los perfiles repetidos serán eliminados.
En los campos de perfil marcados con un comentario apropiado, no puede poner otra información que no sea el comentario resultante. Para evitar dudas, solo el nombre y el apellido de un profesional determinado pueden ingresarse en el campo marcado con el 'nombre y apellido'. Esta regla se aplica a otros campos, respectivamente.
La verificación de los perfiles se realiza con base en la validación de cédulas profesionales de los Profesional de la Salud y el INE del profesional de la salud.
Kenko tiene el derecho solicitar al Especialista en Salud la rectificación de información que se estime inexacta.
Kenko tiene el derecho de validar, por sí o a través de terceros, la información y la documentación proporcionada por el Profesional de la Salud, únicamente con la finalidad de confirmar que cuente con las autorizaciones gubernamentales requeridas para su práctica profesional. En caso de que dicha información sea falsa o inexacta, Kenko podrá suspender el perfil del Especialista en Salud.
XII.- CENTRO DE ATENCIÓN Y SOPORTE
Para realizar cualesquier solicitudes, quejas o reportes relacionados con el Servicio, los Usuarios deberán comunicarse al Centro de Atención de Kenko, dentro del plazo máximo de cinco (5) días naturales posteriores al acontecimiento que deseen reportar o cuya aclaración soliciten.

Centro de Atención y Soporte:

Teléfono desde la Ciudad de México: (+52) 999 250 1974

Correo electrónico: support@kenko.mx
</p>
<p className="fs-6 c-wedgewood"> 

XI.- USO DE LOS MEDIOS DIGITALES
Los Usuarios se comprometen a hacer un uso adecuado y lícito de los Medios Digitales, de conformidad con la legislación aplicable, el presente instrumento, la moral y las buenas costumbres generalmente aceptadas y el orden público. Los Usuarios deberán abstenerse de incurrir en las siguientes conductas: (i) hacer un uso inadecuado, ilícito o fraudulento de los Medios Digitales; (ii) acceder o intentar acceder a recursos restringidos o no autorizados dentro de los Medios Digitales; (iii) utilizar los Medios Digitales con fines o propósitos ilícitos, ilegales, contrarios a lo establecido en el presente instrumento, lesivos de los derechos o intereses de terceros, o que de cualquier forma puedan dañar, inutilizar o sobrecargar o impedir la normal utilización o disfrute del Servicio; (iv) provocar daños en los Medios Digitales o en los sistemas de sus proveedores o de terceros; (v) introducir, difundir o realizar actos tentativos a distribuir virus informáticos o cualesquier otros sistemas físicos o lógicos que sean susceptibles de causar daños en los sistemas de Kenko, de sus proveedores o de terceros; (vi) intentar, acceder, utilizar y/o manipular los datos de Kenko; terceros proveedores y otros Usuarios; (vii) reproducir o copiar o distribuir cualesquier elementos insertos en los Medios Digitales, sin contar con la autorización correspondiente; y (vii) realizar o intentar realizar ingeniería inversa, descompilar o hacer compilación inversa, desensamblar, listar, imprimir o mostrar el software, firmware, middleware o cualquier otra parte del código fuente o compilado que integra Kenko y cualquiera de sus programas o componentes, o por cualquier otro medio obtener el código fuente u otra información patrimonial de los Medios Digitales.

Asimismo, queda estrictamente prohibido para el Usuario la realización de "Operaciones Fraudulentas" en los Medios Digitales. Para efectos del presente documento, se entenderá por Operaciones Fraudulentas, cualquier acto realizado por los Usuarios para obtener un lucro o beneficio indebido, incluyendo sin limitar la suplantación de identidades; la creación de cuentas falsas o cuentas dobles de usuario; el ingreso de información financiera o bancaria falsa, o previamente sustraída a terceros; la confabulación con cualquier otro Usuario o con cualquier personal de Kenko para realizar conductas fraudulentas; y en general, cualquier acto aislado o reiterado que tenga como propósito engañar a la Plataforma o a Kenko para obtener un lucro indebido.

El Usuario acepta que la disponibilidad y continuidad del funcionamiento de los Medios Digitales podrá verse interrumpida o suspendida temporalmente por: (i) la falta de disponibilidad o accesibilidad a los Medios Digitales; (ii) la interrupción en el funcionamiento de los Medios Digitales por fallos informáticos, averías telefónicas, desconexiones, retrasos o bloqueos causados por deficiencias o sobrecargas en las líneas telefónicas, centros de datos, en el sistema de internet o en otros sistemas electrónicos, producidos en el curso de su funcionamiento; y (iii) otros daños que puedan ser causados por terceros mediante intromisiones no autorizadas ajenas al control de Kenko.

Kenko hará sus mejores esfuerzos por mantener el buen y correcto funcionamiento de los Medios Digitales, así como para mitigar la presencia de virus y/o de otros elementos introducidos a los Medios Digitales por terceros ajenos, además adoptará diversas medidas de protección para proteger los Medios Digitales contra ataques informáticos de terceros. Sin embargo, los Usuarios reconocen que los Medios Digitales podrán no estar disponibles debido a dificultades técnicas, fallas en el internet, afectaciones en las redes de telecomunicaciones, o por cualquier circunstancia ajena a Kenko.
</p>
<p className="fs-6 c-wedgewood"> 

XII - SALVAGUARDA DE USUARIOS
Los Usuarios entienden que la seguridad y la salvaguarda de la integridad física y psicológica de los Usuarios es un requisito indispensable para el uso de Kenko.

En caso de (i) incumplimiento de estos Términos y Condiciones o, de existir, del contrato o contratos adicionales entre Kenko y el Usuario; (ii) conducta de un Usuario que pueda causar un riesgo, daño o perjuicio a otros Usuarios (por ejemplo, un Profesional de la Salud respecto de un Paciente o viceversa) (iii) incumplimiento de la legislación aplicable o códigos deontológicos profesionales aplicables, (iv) indicios de comportamiento delictivo y/o (v) comportamiento indebido que pudiera perjudicar considerablemente la reputación de Kenko o de otros Usuarios e individuos, Kenko podrá, salvo que la legislación aplicable lo prohíba, suspender o restringir el acceso a la Cuenta de Usuario y a la totalidad o parte de los Servicios sin previo aviso.

Por “comportamiento indebido” se entiende cualquier comportamiento difamatorio, inapropiado, amenazador, odioso o que pueda atentar contra la integridad física o moral de otro Usuario o individuo,

En aquellos casos que Kenko considere graves, Kenko podrá proceder a la cancelación definitiva de la Cuenta de Usuario. Dicha cancelación comportará la finalización de los Servicios y, de existir, del contrato o contratos adicionales existentes, a todos los efectos.

En la medida de lo posible, el Usuario será informado de la suspensión, restricción o cancelación de la Cuenta de Usuario por los medios que Kenko considere oportunos y tendrá derecho a expresar su punto de vista para su valoración por parte de Kenko.

Kenko no ostentará responsabilidad alguna frente a los Usuarios o terceros en caso de suspensión, restricción o cancelación definitiva de la Cuenta de Usuario.

</p>
<p className="fs-6 c-wedgewood"> 

XIII- PRIVACIDAD
Al suscribir o aceptar los presentes Términos y Condiciones, los Usuarios declaran que han leído y han aceptado expresamente el Aviso de Privacidad de Kenko, que se encuentra a su disposición en el Sitio Web. Para más información sobre el tratamiento de sus datos personales, los Usuarios deberán consultar el Aviso de Privacidad.

XVI.- DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL
Los Usuarios reconocen y aceptan que todos los derechos de propiedad intelectual e industrial sobre la Plataforma y elementos insertos en los Medios Digitales (incluyendo sin limitar marcas, logotipos, figuras, nombres comerciales, textos, imágenes, gráficos, diseños, sonidos, bases de datos, software, diagramas de flujo, presentaciones, y elementos de audio y de video) pertenecen a Kenko.

Se encuentra estrictamente prohibida cualquier reproducción, distribución, transmisión, copia, alteración, explotación, publicación, difusión o disposición de la Propiedad Intelectual, sin el consentimiento previo y por escrito de un representante legal de Kenko.

Cualquier uso o explotación de la Propiedad Intelectual, que no sea autorizado por un representante legal de Kenko previamente y por escrito, será motivo suficiente para cancelar la Cuenta del Usuario, sin perjuicio de las sanciones administrativas, civiles y penales a las que sea acreedor el infractor.
</p>
<p className="fs-6 c-wedgewood"> 

XIV.- RELACIÓN ENTRE LAS PARTES
Para efectos de los presentes Términos y Condiciones, los Usuarios y Kenko en conjunto son denominadas como las "Partes". Las Partes reconocen que todas ellas son independientes entre sí, por lo que consienten que la suscripción de los presentes Términos y Condiciones será una relación contractual de carácter privado, sin que dé lugar a que dicha relación se interprete como una sociedad o asociación entre ellas.
</p>
<p className="fs-6 c-wedgewood"> 

XV.- CAUSAS DE FUERZA MAYOR
Kenko no será responsable frente a los Usuarios, conforme al presente instrumento, en caso de que no puedan utilizarse los Medios Digitales o no se pueda prestar el Servicio por causas de fuerza mayor, incluyendo, pero sin estar limitado a, incendios, inundaciones, huracanes, tormentas, ciclones, terremotos, sismos, movimientos telúricos, huelgas, guerras, insurrecciones, motines, rebeliones, clausuras, ataques cibernéticos de hackers, casos fortuitos u otras causas de naturaleza similar o distinta.
</p>
<p className="fs-6 c-wedgewood"> 
XVI.- CESIÓN
Los Usuarios no podrán ceder sus derechos y obligaciones asumidos al suscribir los presentes Términos y Condiciones, sin el consentimiento previo y por escrito de un representante legal de Kenko. Por su parte, Kenko podrá ceder los presentes Términos y Condiciones a cualquier tercero que le suceda en el ejercicio de su negocio o en que asuma la titularidad de los Medios Digitales o que se encuentre dentro de su mismo grupo empresarial.
</p>
<p className="fs-6 c-wedgewood"> 
XVII.- NOTIFICACIONES
Kenko podrá realizar las notificaciones oportunas a través de una publicación general difundida en los Medios Digitales, a través de un aviso dirigido a la dirección de correo electrónico facilitada por los Usuarios al registrar sus Cuentas de Usuario o mediante notificación por correo tradicional dirigido al domicilio indicado por los Usuarios al registrar sus Cuentas de Usuario. El Usuario podrá notificar a Kenko mediante el envío de un correo electrónico a la dirección de contacto señalada en el Sitio Web.
</p>
<p className="fs-6 c-wedgewood"> 
XVIII.- MODIFICACIONES
Los presentes Términos y Condiciones podrán ser modificados, adicionados o reformados por Kenko, en cuyo caso se actualizará la fecha de última modificación del presente instrumento. El Usuario reconoce expresamente el derecho de Kenko de modificar estos Términos y Condiciones en cualquier tiempo, debiendo consultar periódicamente los Términos y Condiciones vigentes en cada momento.

En el evento de que el Usuario no esté de acuerdo con las modificaciones realizadas a estos Términos y Condiciones, este podrá solicitar a Kenko la terminación del mismo dentro de los treinta (30) días naturales posteriores al aviso, sin responsabilidad alguna a su cargo. Se entenderá que el Usuario acepta las modificaciones efectuadas a los presentes Términos y Condiciones si solicita el Servicio en fecha posterior a que tales modificaciones entren en vigor, o si transcurrido dicho plazo no ejercitó su derecho de revocación.
</p>
<p className="fs-6 c-wedgewood"> 
XIX.- SUBSISTENCIA
En caso de que cualquier término, condición, sección o disposición prevista en los presentes Términos y Condiciones sea considerada o pudiera considerarse nulo, inválido, ilegal, prohibido o inexigible en cualquier jurisdicción, dicho término, condición, sección o disposición deberá separarse del presente Contrato, sin que lo anterior implique la nulidad o la invalidez de los términos o condiciones restantes. Kenko deberá sustituir el término, condición, sección o disposición correspondiente, de conformidad con la normatividad aplicable.
</p>
<p className="fs-6 c-wedgewood"> 
XX.- VIGENCIA
Los presentes Términos y Condiciones estarán vigentes y serán obligatorios y vinculantes para todos los Usuarios (incluyendo los Usuarios que tengan una Cuenta de Usuario habilitada en los Medios Digitales y no soliciten su baja o cancelación).
</p>
<p className="fs-6 c-wedgewood"> 
XXI.- RESPONSABILIDAD DE Kenko
Al suscribir los presentes Términos y Condiciones, los Usuarios quedan notificados sobre el alcance del Servicio, mismo que se circunscribe y se limita a proveer herramientas digitales a profesionales de la salud para la realización y administración de su práctica clínica.

Asimismo, los Pacientes han quedado suficientemente enterados de que Kenko no recomienda profesionales de la salud, ni verifica su idoneidad profesional, ni la autenticidad de los documentos que respaldan su historial académico o profesional.


En ese entendido, con fundamento en el artículo 2117 del Código Civil Federal y sus correlativos y aplicables en los Códigos Civiles de las Entidades Federativas de la República Mexicana, los Pacientes renuncian expresamente y en los términos más amplios permitidos por la legislación vigente a ejercitar cualquier acción, demanda, denuncia, reclamación o a intentar cualquier procedimiento judicial o extrajudicial en contra de Kenko, derivado o motivado en los daños y/o perjuicios (o inclusive daños morales) que puedan resentir con motivo de la prestación de servicios profesionales que desempeñen los Especialistas en Salud o en su caso, con los Centros Médicos, incluyendo pero no limitado a casos de negligencia médica, mala praxis, diagnósticos erróneos o efectos secundarios por tratamiento médico.

Por su parte, tanto los Especialistas en Salud como los Centros Médicos al aceptar estos Términos y Condiciones, reconocen que son y serán los únicos responsables de contar con cualquier permiso, aviso, registro, o cualquier otro requerido por la legislación vigente y aplicable, necesarios para practicar la medicina, prestar sus servicios profesionales, promocionar productos, servicios, establecimientos, insumos, y/o cualquier otro que por su naturaleza así lo exija la ley y bajo el entendido de que Kenko no es ni será responsable bajo ninguna circunstancia, del contenido que el Especialista en Salud o Centro Médico agreguen a su Perfil sin contar con los requisitos previstos en la legislación aplicable.

Los Especialistas en Salud y en su caso, los Centros Médicos se obligan a sacar en paz y a salvo e indemnizar a Kenko, así como a sus socios, accionistas, inversionistas, representantes, apoderados, agentes, asesores legales, prestadores de servicios y empleados de cualesquier gastos, daños y/o perjuicios (incluyendo honorarios razonables de abogados) resientan estos últimos con motivo de la interposición de cualesquier denuncias, demandas, litigios, inspecciones, verificaciones o en general, procedimientos judiciales, administrativas o extrajudiciales que se inicien en contra de Kenko o de las personas antes referidas.
</p>
<p className="fs-6 c-wedgewood"> 

XXII.- LEGISLACIÓN APLICABLE Y JURISDICCIÓN COMPETENTE
Las Partes en este acto convienen de manera terminante que los presentes Términos y Condiciones serán regidos e interpretados de acuerdo con la legislación civil federal vigente en México, por lo que las Partes renuncian, de manera expresa e irrevocable, a cualquier otra legislación que pudiera ser aplicable en virtud de sus domicilios presentes o futuros o por cualquier otra razón o concepto.

Para cualquier controversia relacionada con la validez, la interpretación y el cumplimiento de los presentes Términos y Condiciones, las Partes expresa e irrevocablemente acuerdan y consienten en someterse exclusivamente a la jurisdicción de los juzgados y tribunales competentes sitos en la Ciudad de Mérida, Yucatán, y en este acto expresa e incondicionalmente renuncian, en los términos más amplios permitidos por la ley, a cualquier otro fuero que pudiera corresponderles por razón de sus domicilios actuales o futuros o por cualquier otra razón.
            </p>
        </Col>
    </Row>
  </Layout>
)

export default Terms
